






































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineELN3OISafety2',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        listedChemicals: null,
        safety2Answer: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: "List any chemicals used during the original investigation not used during fundamental skills. Include each chemical's appropriate identifying information.",
          inputName: 'listedChemicals',
        },
        {
          text: 'Safety Moment:',
          inputName: 'safety2Answer',
        },
      ],
    };
  },
});
